import $ from 'jquery'
import Rails from 'rails-ujs'
import Foundation from 'foundation-sites'
import * as ActiveStorage from 'activestorage'
import Turbolinks from 'turbolinks'
import 'slick-carousel/slick/slick'
import 'slick-carousel/slick/slick.css'

import 'application.scss'

require.context('../images', true)

Rails.start()
Foundation.addToJquery($)
ActiveStorage.start()
Turbolinks.start()

$(document).on('turbolinks:load', function () {
  $(this).foundation()

  $('.project-filter').on('change', function () {
    $(this).parent().submit()
  })

  const updateExpertiesImage = () => {
    const expertiesLinks = $('[data-link]').toArray()
    const expertiesImage = $('[data-link-image]')
    const firstImage = expertiesLinks[0].getAttribute('data-image')
    expertiesImage.css('background-image', `url(${firstImage})`)

    expertiesLinks.forEach(function (ele) {
      ele.addEventListener('mouseover', function () {
        const linkImage = ele.getAttribute('data-image')
        expertiesImage.css('background-image', `url(${linkImage})`)
      })
    })
  }

  if ($('[data-link]').toArray().length > 0) {
    updateExpertiesImage()
  }

  if ($('.form_has_error').length > 0) {
    $('html, body').animate(
      {
        scrollTop: $('.contact-form').offset().top,
      },
      1000
    )
  }

  const headerScrollClass = () => {
    const $header = $("[data-module='header']")
    $(window).scrollTop() > 0 ? $header.addClass('header--active') : $header.removeClass('header--active')
  }

  headerScrollClass()
  $(window).on('scroll', () => headerScrollClass())

  $("[data-slick='client-logos']").slick({
    dots: false,
    arrows: false,
    slidesToShow: 6,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  })

  $("[data-slick='image-slider']").slick({
    dots: false,
    arrows: true,
    infinite: true,
  })

  $("[data-slick='hero-slider']").slick({
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    draggable: false,
    infinite: true,
  })

  $("[data-slick='step-slider']").slick({
    dots: false,
    arrows: true,
    slidesToShow: 2,
    infinite: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  })

  function navigationDropdown() {
    const dropdownToggles = [...document.querySelectorAll('[data-toggle-dropdown]')]

    dropdownToggles.forEach(function (toggle) {
      const toggleName = toggle.getAttribute('data-toggle-dropdown')
      const dropdownTarget = document.getElementById(toggleName)

      if (dropdownTarget) {
        toggle.addEventListener('mouseover', () => dropdownTarget.classList.add('toggle-dropdown--active'))
        toggle.addEventListener('mouseleave', () => dropdownTarget.classList.remove('toggle-dropdown--active'))

        dropdownTarget.addEventListener('mouseover', () => {
          dropdownTarget.classList.add('toggle-dropdown--active')
          toggle.classList.add('is-active')
        })

        dropdownTarget.addEventListener('mouseleave', () => {
          dropdownTarget.classList.remove('toggle-dropdown--active')
          toggle.classList.remove('is-active')
        })
      }
    })
  }

  navigationDropdown()
})
